import Main from "./Main.js";
import Nav from "./Nav.js";
import Footer from "./Footer.js";

function App() {
  return (
    <>
      <Nav />
      <Main />
      <Footer />
    </>
  );
}

export default App;
