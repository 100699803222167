import React from "react";
import { Routes, Route } from "react-router-dom";

import Accueil from "./accueil";
import About from "./qui-sommes-nous";
import Services from "./services";
import Contact from "./contact";

const Main = () => {
  return (
    <Routes>
      {" "}
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path="/" element={<Accueil />} />
      <Route exact path="/qui-sommes-nous" element={<About />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default Main;
