import React, { useState, useEffect } from "react";

const Slideshow = ({ images }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = images.map((image) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = image;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(imagePromises);
        setPreloadedImages(images);
        setImageLoaded(true);
      } catch (error) {
        console.error("Error preloading images:", error);
      }
    };

    preloadImages();
  }, [images]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + 1 === preloadedImages.length ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, preloadedImages]);

  return (
    <div className="slideshow">
      {imageLoaded && (
        <img
          key={currentIndex}
          src={preloadedImages[currentIndex]}
          alt={`slide-${currentIndex}`}
          className="img-fluid"
        />
      )}
    </div>
  );
};

export default Slideshow;
