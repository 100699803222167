import React, { useState, useEffect } from "react";
import "./css/carousel.css";

const Carousel = ({ images }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const [currentImageTitle, setCurrentImageTitle] = useState("");

  useEffect(() => {
    const preloadImages = () => {
      const imagePromises = images.map((image) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = `${process.env.PUBLIC_URL}/${image.url}`;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      Promise.all(imagePromises)
        .then(() => {
          setPreloadedImages(images);
          setImageLoaded(true);
        })
        .catch((error) => {
          console.error("Error preloading images:", error);
        });
    };

    preloadImages();
  }, [images]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === preloadedImages.length ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? preloadedImages.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    setCurrentImageTitle(preloadedImages[currentIndex]?.title || "");
  }, [currentIndex, preloadedImages]);

  return (
    <div className="carousel">
      {imageLoaded && (
        <>
          <img
            key={currentIndex}
            src={`/${preloadedImages[currentIndex].url}`}
            alt={`slide-${currentIndex}`}
          />
          <div className="image-title">{currentImageTitle}</div>
          <div className="left" onClick={handlePrevious}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 96 960 960"
              width="20"
            >
              <path d="M400 976 0 576l400-400 56 57-343 343 343 343-56 57Z" />
            </svg>
          </div>
          <div className="right" onClick={handleNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 96 960 960"
              width="20"
            >
              <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

export default Carousel;
