import "./css/App.css";
import Slideshow from "./Slideshow";
import vof from "./images/VOFbanner2023 - resized 850kb.jpg";
const images = [
  "/slideshow/footer-services.jpg",
  "/slideshow/footer-services1.jpg",
  "/slideshow/footer-services5.png",
  "/slideshow/footer-services2.jpg",
  "/slideshow/footer-services3.jpg",
];
function Services() {
  return (
    <>
      <div>
        <div>
          <h1>Nos Services</h1>
          <div className="bottom-bar"> </div>
        </div>
        <div id="services-particuliers">
          <h2>Services aux particuliers</h2>
          <h4>
            Intervention psychosociale et relation d’aide assistée par l’animal
          </h4>
          <p>
            Des séances de zoothérapie individuelle en fratrie ou familiale sont
            maintenant possibles dans notre tout nouveau bureau, situé à
            St-Paul-de-l’île-aux-noix, au calme en pleine campagne.
          </p>
          <p>
            Lorsque la température le permet, il est possible de profiter de la
            nature, dans le boisé qui nous entoure, en compagnie d’un partenaire
            de zoothérapie et l’intervenante.
          </p>
          <p>
            Nous prenons le temps de bien identifier les objectifs à court et
            long terme et établissons un plan d’intervention pour bien vous
            accompagner.{" "}
          </p>
          <p>
            Au bureau, vous pouvez croiser plusieurs chiens, des chats et même
            notre lapin, Monsieur Louise.
          </p>

          <h4>Intervention assisté par le cheval</h4>
          <p>
            Notre approche unique, dans le respect de l’animal, le calme et la
            douceur, amène un apaisement indiscutable. Nous travaillons le
            maniement au sol du cheval par l’usagé, l’éducation sur les besoins
            des chevaux, les soins de base, parcours et bien d’autre sont à
            l’honneur. Nous travaillons toujours en duo de zoothérapeute et
            entraineur, pour assurer un maximum de sécurité durant la séance.
          </p>
          <p>
            Sur place, 2 manèges intérieurs et un espace pour l’attente sont à
            votre disposition.
          </p>
          <p>Situé à St-Jean-Sur-Richelieu à la Ferme Vintage Oasis.</p>
          <div className="center">
            <button>
              <a
                href="https://www.vintageoasisfarm.com"
                target="_blank"
                title="FermeVintageOasisFarm"
              >
                <div className="row">
                  <img
                    src={vof}
                    className="img-fluid vof-logo"
                    alt="vintage oasis farm"
                  />
                </div>
                <div className="row">
                  <p className="center">www.vintageoasisfarm.com</p>
                </div>
              </a>
            </button>
          </div>
        </div>
        <div className="row flex-column flex-md-row" id="other-services">
          <div className="col-12 col-md-6">
            <h2>Organismes & Institutions</h2>
            <h4>Séances de groupe/ suivis individuels</h4>
            <p>
              En contexte institutionnel ou communautaire, la zoothérapie est
              utilisée comme complément au programme thérapeutique ou aux
              activités cliniques déjà en place afin de stimuler l’intérêt du
              client. L’animal agit comme catalyseur dans la relation d’aide,
              facilite la mise en place du lien de confiance et optimise
              l’intégration. Les interventions peuvent ainsi s’orchestrer dans
              le cadre de séances individuelle ou de groupe, sous forme de
              discussion, d’activités psychomotrices, de stimulation cognitive
              et intellectuelle ou encore de développement personnel et
              interpersonnel, selon les besoins de la clientèle.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h2>Programmes Éducatifs & Apprentissages</h2>
            <h4>Prévention, accompagnement & support</h4>
            <p>
              Les séances de zoothérapie «Éducations & Apprentissages» sont
              axées de manière à venir en support aux apprentissages académiques
              et personnels des enfants et adolescents d’âge préscolaire et
              scolaire. Stimulant l’intérêt de la clientèle, les séances de
              zoothérapie dans le contexte d’apprentissage permettent
              d’optimiser entre autre le développement du langage, la prise en
              charge et l’intégration de notions académiques, la gestion des
              émotions, la gestion de l’impulsivité/agressivité et le
              développement de diverses habiletés sociales comme la
              communication et la collaboration.
            </p>
          </div>
        </div>
      </div>
      <div>
        <Slideshow images={images} />
      </div>
    </>
  );
}
export default Services;
