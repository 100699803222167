import React, { useState } from "react";
import logo from "./images/logo.png";
import "./css/nav.css";
import "bootstrap/dist/css/bootstrap.css";

function Nav() {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <nav className="navbar navbar-expand-md">
      <img src={logo} className="main-logo" alt="logo"></img>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${collapsed ? "" : "show"}`}
        id="collapsibleNavbar"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="/">
              Accueil
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/qui-sommes-nous">
              Qui sommes nous
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/services">
              Nos services
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contact">
              Nous joindre
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
