import "./css/App.css";
import "bootstrap/dist/css/bootstrap.css";
import mainphoto from "./images/main-kara-photo 2,1.jpg";
import Carousel from "./Carousel.js";
const imagesCurrent = [
  { url: "./equipe/Bertrand.jpg", title: "Bertrand" },
  { url: "./equipe/Daisy.jpg", title: "Daisy" },
  { url: "./equipe/dixie.jpg", title: "Dixie" },
  { url: "./equipe/Georges.jpg", title: "Georges" },
  { url: "./equipe/gus.jpg", title: "Gus" },
  { url: "./equipe/kara.jpg", title: "Kara" },
  { url: "./equipe/Maui.jpg", title: "Maui" },
  { url: "./equipe/Peach.jpg", title: "Peach" },
  { url: "./equipe/Romeo.jpg", title: "Romeo" },
  { url: "./equipe/Scarlett.jpg", title: "Scarlett" },
  { url: "./equipe/Siam.jpg", title: "Siam" },
  { url: "./equipe/Simone.jpg", title: "Simone" },
  { url: "./equipe/vadrouille.jpg", title: "Vadrouille" },
  { url: "./equipe/Whippet.jpg", title: "Whippet" },
];

const imagesRetired = [
  { url: "./retired/Bella.jpg", title: "Bella" },
  { url: "./retired/Dez.jpg", title: "Dez" },
  { url: "./retired/gisele.jpg", title: "Gisele" },
  { url: "./retired/jack.jpg", title: "Jack" },
  { url: "./retired/Lark.jpg", title: "Lark" },
  { url: "./retired/lilou.jpg", title: "Feu Lilou" },
  { url: "./retired/lino.jpg", title: "Lino" },
  { url: "./retired/Odin.jpg", title: "Feu Odin" },
  { url: "./retired/queen.jpg", title: "Queen" },
  { url: "./retired/tao.jpg", title: "Feu Tao" },
];

function Accueil() {
  return (
    <>
      <div className="image-container">
        <img src={mainphoto} className="main-photo img-fluid" alt="kara" />
        <div className="image-text">
          <p className="title">Zoothérapie du Haut Richelieu</p>
        </div>
        <div className="additional-text">
          <h1>Description de l'entreprise</h1>
          <p>
            Zoothérapie du Haut-Richelieu offre des services d’Interventions
            Assistées par l’Animal à une clientèle ayant différentes difficultés
            d’adaptation. Le but: Intégrer un chien dans le processus
            d’intervention afin de stimuler l’intérêt, optimiser la relation
            d’aide et favoriser la participation active du client. En se
            déplaçant directement dans les différents établissements,
            Zoothérapie du Haut-Richelieu cherche à offrir, dans le milieu de
            vie du client, des interventions adaptées à ses besoins.
          </p>
          <h1>Mission</h1>
          <p>
            Zoothérapie du Haut-Richelieu se veut une entreprise accessible et
            près de sa communauté. Elle se donne comme mission de promouvoir et
            faire rayonner les bienfaits des Interventions Assistées par
            l’Animal, tant auprès des institutions, des organismes que des
            particuliers.
          </p>
        </div>
      </div>
      <div className="equipe-container">
        <h1>Notre équipe</h1>
        <Carousel images={imagesCurrent} />
      </div>
      <div className="equipe-container" id="retired">
        <h1>Nos chiens à la retraite</h1>
        <Carousel images={imagesRetired} />
      </div>
    </>
  );
}

export default Accueil;
