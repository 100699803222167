import "./css/footer.css";
import logo from "./images/logo.png";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer">
      <div className="row">
        <div className="col-12 col-md-4 center">
          <img src={logo} className="img-fluid" alt="zootherapiehr logo" />
        </div>
        <div className="col-12 col-md-4 center">
          <button id="socialmedia">
            <a
              href="https://www.facebook.com/zootherapieHR"
              target="_blank"
              title="facebook ZootherapieHR"
              id="socialmedia"
            >
              <i className="fab fa-facebook-square" aria-hidden="true"></i>
            </a>
          </button>
        </div>
        <div className="col-12 col-md-4 center">
          <button onClick={scrollToTop} id="gototop">
            <i className="fa fa-chevron-circle-up" aria-hidden="true"></i>
            <span>Retour en haut</span>
          </button>
        </div>
      </div>
    </div>
  );
}
export default Footer;
