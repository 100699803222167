import "./css/App.css";
import "./css/contact.css";
import kids from "./images/kids.jpg";
function Contact() {
  return (
    <div className="image-container">
      <img src={kids} className="main-photo img-fluid" alt="kara" />
      <div className="additional-text contact" id="contact">
        <h1>Nous joindre</h1>
        <h3>Adresse</h3>
        <h5>Nos bureaux sont situés à St-Paul-de-l’Île-aux-Noix.</h5>
        <h3>Téléphone</h3>
        <button>
          <a href="tel:+14388880574">1 (438) 888-0574</a>
        </button>
        <h3>Adresse Courriel</h3>
        <button>
          <a href="mailto:zootherapiehr@hotmail.com">
            zootherapiehr@hotmail.com
          </a>
        </button>
      </div>
    </div>
  );
}
export default Contact;
