import "./css/about.css";
import "./css/App.css";
import historique from "./images/historique.jpg";
import profilepic from "./images/amelie.jpg";
function About() {
  return (
    <>
      <div className="row flex-column flex-md-row" id="amelie">
        <div className="col-12 col-md-3">
          {" "}
          <img src={profilepic} className="profilepic" alt="amelie" />
        </div>
        <div className="col-12 col-md-9">
          <h2>Amélie Tétreault</h2>
          <p>
            Propriétaire de Zoothérapie du Haut-Richelieu, depuis septembre
            2019, Amélie est éducatrice à l’enfance de formation. Ensuite, elle
            à été formée chez Zoothérapie Québec et, cumulant diverses
            formations en lien avec la petite enfance, Amélie Tétreault se
            passionne pour l’intervention auprès des enfants, depuis maintenant
            plus de 10 ans. Ayant de nombreuses années d’expériences en centres
            de la petite enfance et en tant que responsable de services de garde
            en milieu familiale, elle ajoute à cette expérience le rôle de maman
            de trois jeunes enfants, âgés de moins de huit ans.
          </p>
          <p>
            {" "}
            C’est lors de ses années comme responsable d’un service de garde en
            milieu familial que le lien se fait entre son métier d’éducatrice à
            l’enfance et sa passion pour les animaux, ce qui aura comme
            dénouement une formation comme intervenante en zoothérapie.
          </p>
          <p>
            Son rôle de mère l’amène à voir différemment l’interaction entre les
            parents, leurs enfants et le système d’éducation mit en place.
            Amélie prend conscience qu’elle peut jouer un rôle plus significatif
            dans le cheminement de plusieurs familles avec un chien comme
            partenaire.
          </p>
          <p>
            Responsable du programme de prévention «Gamins-Canin» et
            actuellement intervenante auprès des enfants en situation de
            vulnérabilité, Amélie est désireuse de se perfectionner et de varier
            ses champs d’intervention. Elle suit diverses formations sur le
            comportement canin et entame un certificat universitaire en
            gérontologie à l’Université de Montréal.
          </p>
        </div>
      </div>
      <div className="image-container">
        <img src={historique} className=" img-fluid" alt="kara" />
        <div className="additional-text historique">
          <h1>Historique</h1>
          <p>
            Formée chez Zoothérapie Québec et ensuite prenant de l’expérience
            auprès de la fondatrice de Zoothérapie du Haut-Richelieu, Frédérique
            Hébert, Amélie Tétreault développera son champ d’expertise auprès
            d’une clientèle variée.
          </p>
          <p>
            Pour Amélie, agir dans sa communauté a toujours représenté un
            objectif de carrière. C’est d’ailleurs ce qui la pousse à prendre
            les reines de Zoothérapie du Haut-Richelieu, lorsque l’occasion lui
            est offerte; afin de pouvoir faire rayonner les interventions
            Assistées par l’Animal en Montérégie.
          </p>
        </div>
      </div>
    </>
  );
}
export default About;
